<template>
  <v-container fluid>
    <Notify></Notify>
    <v-row>
      <!-- <v-col cols="12" class="text-center">
        <h3 class="text-h3 text-typo font-weight-bold mt-9">
          Build {{ $store.state.card.title }}
        </h3>
      </v-col> -->
      <v-col :md="col" cols="12" class="mb-6 mx-auto">
        <slot name="card-body">
          <!-- <v-row class="text-center mt-9">
            <v-col cols="10" class="mx-auto">
              <h5
                v-if="$store.state.card.sub_title"
                class="text-h5 text-typo font-weight-bold mb-6 mt-3"
              >
                {{ $store.state.card.sub_title }}
              </h5>
            </v-col>
          </v-row> -->
          <v-card class="card-shadow border-radius-lg">
            <Loading v-if="$store.state.card.loading"></Loading>
            <div class="border pa-6">
              <h5
                v-if="$store.state.card.sub_title"
                class="text-h5 text-typo font-weight-bold"
              >
                {{ $store.state.card.sub_title }}
              </h5>
            </div>
            <div class="px-6 border py-6">
              <slot name="card-form">
                <Form>
                  <template slot="filed">
                    <slot name="form"> </slot>
                  </template>
                  <template slot="customize">
                    <slot name="customize-form"> </slot>
                  </template>
                </Form>
              </slot>
            </div>
          </v-card>
        </slot>
      </v-col>
      <slot name="card-section-2"> </slot>
    </v-row>
  </v-container>
</template>
<script>
import Notify from "./Notify.vue";
// import Stepper from "./Stepper.vue";
import Form from "./Form.vue";
import Loading from "./Loading.vue";
export default {
  name: "Card",
  components: {
    Notify,
    // Stepper,
    Form,
    Loading,
  },
  props: {
    col: {
      default: 8,
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="css">
.border-card {
  border-radius: 1rem !important;
}
</style>
